import React from 'react';

import { Layout } from '../components/layouts';
import { SEO } from '../components/common';
import { ErrorSection } from '../components/sections';

const NotFoundPage = (): JSX.Element => (
  <Layout>
    <SEO title="404: Not found" />
    <ErrorSection
      code="404"
      message="You just hit a page that doesn't exist... the sadness."
    />
  </Layout>
);

export default NotFoundPage;
